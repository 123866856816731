import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css'; // Kendi stil dosyanızı ekleyin

function Login() {  // setCurrentUser prop'unu al
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://payprintgo.com:3001/api/login', { email, password });
      sessionStorage.setItem('user', JSON.stringify(response.data.user.name));
      sessionStorage.setItem('userInfo', JSON.stringify(response.data.user));

      window.location.reload(true);

        // Eklendi: Girişten sonra durumu güncelleyin
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Bir hata oluştu.');
      }
    }
  };

  return (
    <div className="Login">
      <div className="bg-dark d-flex justify-content-center align-items-center vh-100">
        <div className="card" style={{ maxWidth: '500px', margin: 'auto' }}>
          {error && <div className="text-danger">{error}</div>}
          <div className="card-header bg-primary text-white text-center">
            <h2>Box Stock Login</h2>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  style={{ minWidth: '102%' }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  style={{ minWidth: '102%' }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="card-footer text-center">
                <button type="submit" className="btn btn-primary">Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
